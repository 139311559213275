.lift-img {
    object-fit: cover;
}
.toolbar-content .responsive-toolbar-container {
    justify-content: center;
}
.container-img-lsp {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}
.responsive-book-container .class-left-card img {
    width: 100%;
}
.label-profile {
    width: unset;
    margin-right: 15px;
}
.content-profile {
    width: 100%;
}
.class-description {
    text-align: justify;
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    .ant-col-offset-5.responsive-toolbar {
        margin-left: 18.833333%;
    }

    .toolbar {
        bottom: 5px;
    }
    /*.toolbar-content .ant-col-offset-3 {
        margin-left: 9.5%;
    }*/
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    .toolbar {
        bottom: 5px;
    }
    /*.toolbar-content .ant-col-offset-3 {
        margin-left: 10.5%;
    }*/
}

@media (min-width: 481px) and (max-width: 767px) {
  
    /*.toolbar-content .ant-col-offset-3 {
        margin-left: 9.5%;
    }*/
    
  }

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .lift-img {
        object-position: 20%;
    }

    .toolbar {
        width: 95%;
        left: 0px;
        bottom: 0px;
        margin: 10px;
    }

    .ant-col-offset-5.responsive-toolbar {
        margin-left: 17.666667%;
    }
    /*.toolbar-content .ant-col-offset-3 {
        margin-left: 9.5%;
    }*/
    .responsive-book-container {
        flex-direction: column;
        margin-bottom: 20px;
    }
    .responsive-book-container .class-left-card, .responsive-book-container .class-right-card {
        width: 100%;
    }
    .class-left-card {
        width: 100% !important;
        margin-right: unset;
    }
    .class-left-card img {
        width: 100%;
        height: 100%;
        padding-right: unset !important;
    }
    .class-card .card-row {
        flex-direction: column;
    }
    .class-right-card {
        margin-top: 25px;
        flex-direction: column;
        width: 100%;
    }
    .ant-row.responsive-row-meeting {
        flex-direction: column;
    }
    .responsive-space-title-meeting {
        margin-bottom: 10px;
    }
}