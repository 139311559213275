html,
body {
  padding: 0;
  margin: 0;

}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.login-button {
  line-height: 1.5;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 40px;
  padding: 8.5px 1.25rem;
  font-size: 14px;
  border-radius: 0.625rem;
  color: #455560;
  border-color: #e6ebf1;
  background: #fff;
  color: rgb(255, 123, 0);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.in-button {
  /* line-height: 1.5; */
  position: relative;
  display: inline-block;

  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  /* box-shadow: 0 2px 0 rgb(0 0 0 / 2%); */
  cursor: pointer;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  /* height: 50px;
  width: 50px; */
  border-radius: 50%;
  color: #455560;
  border-color: none;
  background: unset;
  color: rgb(201, 78, 6);
  /* text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%); */
  animation: zoom-in-zoom-out 0.9s ease infinite;
}

.in-button img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  transform: rotateX(299deg);
}


.action-button {
  font-family: 'Lora', sans-serif !important;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  /* height: 50px;
  width: 50px; */
  border-radius: 50%;
  color: #455560;
  border-color: none;
  background: unset;
  color: rgb(201, 78, 6);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  animation: zoom-in-zoom-out 0.9s ease infinite;
}

.raw-button {
  font-family: 'Lora', sans-serif !important;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  /* height: 50px;
  width: 50px; */
  border-radius: 50%;
  color: #455560;
  border-color: none;
  background: unset;
  color: rgb(201, 78, 6);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  animation: zoom-in-zoom-out 0.9s ease infinite;
}

.raw-button img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  transform: rotateX(299deg);
}

.text-marker-raw {
  font-family: 'Lora', sans-serif !important;
  font-size: 1.7em;
  max-width: 200px;
  color: #fff;
  font-weight: 1000;
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 123, 0); */
}

.action-button img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.text-marker-action {
  font-family: 'Lora', sans-serif !important;
  font-size: 1.5em;
  color: #fff;
  font-weight: 1000;
  /* -webkit-text-stroke-width: 1px; */
  /* -webkit-text-stroke-color: rgb(255, 123, 0); */
}

.partner-slide {
  height: 350px;
  object-fit: cover;
  width: 100%;
}

.text-marker {
  font-family: 'Lora', sans-serif !important;
  line-height: 0.9;
  text-align: center;
  font-size: 1.3em;
  max-width: 100px;
  color: #fff;
  font-weight: 1000;
  /* -webkit-text-stroke-width: 1px; */
  /* -webkit-text-stroke-color: rgb(255, 123, 0); */
}

.toolbar {
  position: absolute;
  z-index: 90;
  bottom: 2px;
  width: 50%;
  left: 25%;
  padding: 1rem;
  background: #fff;
  border-radius: 20px;
  border-top: 6px solid #0c3791;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0c3791 !important;
  font-weight: 700;
}

.ant-btn-primary {
  border-color: #0c3791 !important;
  background: #0c3791 !important;
}

.toolbar .toolbar-content {

  color: #0c3791 !important;
  position: relative;
  top: 8px;
  ;
}

.toolbar .toolbar-content .toolbar-title {

  margin-top: 5px;
}

.toolbar .toolbar-content .toolbar-title .text {
  color: #0c3791 !important;
  position: relative;
}

.toolbar-icon {
  color: rgb(253, 122, 8);
}

.book-image {
  height: 180px;
  object-fit: cover;
}


/* ============================== CLASSROOM MODULE  ======================== */
.class-card .ant-card-body {
  padding: 0 !important;
  margin: 0 !important;
}

.class-card .card-row {
  display: flex;
}

.class-left-card {
  width: 30%;
  display: block;
  margin-right: 20px;
}

.class-left-card img {
  width: -webkit-fill-available;
  padding: 0;
  margin: 0;
  height: 100%;
}

.class-right-card {
  width: 70%;
}

.class-right-card .class-title .title {
  font-size: 2em;
  color: #e56f1b;
  font-weight: bolder;
}

.row-profile {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.label-profile {
  justify-content: flex-start;
  width: 25%;
  color: #ff580b;
}

.content-profile {
  justify-content: flex-end;
  width: 75%;
  font-size: 0.9em;
}

.ant-modal-header {
  border-bottom: none !important;
}

.secondary-icon {
  color: #ff580b !important;
}

.image-meta {
  width: 30%;
}

.image-meta .content-action .in-button {
  animation: unset !important;
}

.image-meta .content-action .in-button img {
  transform: unset !important;
}

.image-meta .content-action .text-marker {
  line-height: 1;
  margin: 0;
  position: relative;
  right: 20px;
}

.image-meta .content-action .action-button {
  animation: unset !important;
}

.image-meta .content-action .text-marker-action {
  position: relative;
  left: 0px;
}

.image-meta .content-action .action-button img {
  transform: unset !important;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.image-meta p {
  font-size: 1em !important;
  font-weight: 300;
  color: rgb(255, 123, 0) !important;
  -webkit-text-stroke-color: unset !important;
}

.exist-image-meta .content-action .text-marker-action {
  position: relative;
  left: 20px;
}


.google-btn {
  width: 184px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .25);
  margin: 0 auto;
}

.google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: white;
}

.google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}

.btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
}

.btn-text :hover {
  box-shadow: 0 0 6px #4285f4;
}

.btn-text :active {
  background: #1669F2;
}

.ant-modal-title {
  color: #ff580b !important;
}

.card-items .ant-card-body {
  padding: 10px !important;
}


.content-action {
  width: 100%;
  text-align: center;
}

.image-meta .content-action {
  width: 100%;
  text-align: left;
}


/* Per marker custom */

#MARKER-BRIDGE-CLASSROOM {
  position: relative !important;
  bottom: 20px !important;
}

#MARKER-BRIDGE-CLASSROOM .text-marker {
  margin-bottom: 45px !important;
}

#MARKER-BRIDGE-CLASSROOM .text-marker {
  margin-bottom: 45px !important;
}

#MARKER-PUBLIC-BOOKS .text-marker-action {
  line-height: 2.4 !important;
}

#MARKER-PARTNER-CORPU .text-marker-action {
  line-height: 2.4 !important;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  font-size: inherit !important;
  color: currentColor !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  left: 10px !important;
  z-index: 2 !important;
  font-size: 20px !important;
  font-weight: bolder !important;
  color: #ff580b !important;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  right: 10px !important;
  z-index: 2 !important;
  font-size: 20px !important;
  font-weight: bolder !important;
  color: #ff580b !important;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #ff580b !important;
  opacity: 1 !important;
}